import { useState } from 'react';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { useStoreMobx } from 'mobx/helpers';

const LogoutListener = observer(() => {
  const store = useStoreMobx();
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(false);

  const clearStore = () => {
    try {
      store.logOut();
    } catch (e) {
      console.error(e);
    }
  };

  if (!loggingOut && store.redirectionToSsoNeeded) {
    setLoggingOut(true);
    clearStore();
    navigate('/login');
  }

  return null;
});

export default LogoutListener;
