import React, { lazy, useEffect } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';
import moment from 'moment';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import convertLanguage from 'helpers/convert-language';
import DetectUrl from 'helpers/detect-url';
import JwtChecker from 'helpers/jwt-checker';
import { useStoreMobx } from 'mobx/helpers';
import { sliceEnvironment } from 'redux/slice/slice.environment';

import AuthLogout from 'components/Auth.Logout';

import Client from 'constants/queries';

import AppSnackbar from './components/App.Snackbar';
import Loader from './components/Loader';
import PageLogin from './components/Page.Login';
import PageTemplate from './components/Page.Template';
import ScrollToTop from './components/UI.ScrollToTop';
import { useAppDispatch, useAppSelector } from './redux/hooks';

const PageAccountManager = lazy(() => import('./components/Page.Account-Manager'));
const PageAssetsManagement = lazy(() => import('./components/Page.Assets-Management'));
const PageBusinessOverview = lazy(() => import('./components/Page.Business-Overview'));
const PageDashboard = lazy(() => import('./components/Page.Dashboard'));
const PageElearning = lazy(() => import('./components/Page.Elearning'));
const PageReports = lazy(() => import('./components/Page.Reports'));
const PageCalendar = lazy(() => import('./@module-calendar/routes/calendar/page'));
const PageCalendarActivity = lazy(() => import('./@module-calendar/routes/calendar-activity/page'));

const App = observer(() => {
  const store = useStoreMobx();
  const { i18n } = useTranslation();
  const brokerInfo = useQuery(Client.GET_BROKER_INFO, Client.GET_BROKER_INFO_DEFAULT_OPTIONS(DetectUrl()));
  const dispatch = useAppDispatch();
  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);
  const userLanguage = userInfo?.data?.getUserByJWT?.language;
  const userLocale = userInfo?.data?.getUserByJWT?.locale;
  const environment = useAppSelector(sliceEnvironment.selectors.environment);

  useEffect(() => {
    dispatch({ type: 'SET_PROJECT_ID', payload: store?.demoProject?.id });

    store.setCheckingJwt(true);
    if (store.brokerId && !userInfo.called) {
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store?.demoProject?.id, false));
      // Set del nome della pagina sul browser
      document.title = store.name ? `Enterprise Platform | ${store.name}` : 'Enterprise Platform';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.brokerId]);

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setLoggedUserJwtData({ userData: userInfo.data.getUserByJWT, checkingJwt: false });
      dispatch({ type: 'SET_USER', payload: userInfo.data.getUserByJWT });
      store.setSystemLanguage(userLanguage);
      dispatch({ type: 'SET_SYSTEM_LANGUAGE', payload: userLanguage });
      moment.locale(i18n.language);

      if (i18n.language.split('-')[0] !== (store.loggedUser?.language || userLanguage) && userLanguage) {
        const convertedLanguage = convertLanguage(userLanguage, userLocale);
        i18n.changeLanguage(convertedLanguage);
        moment.locale(convertedLanguage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage]);

  if (brokerInfo && !brokerInfo.loading && brokerInfo.called && brokerInfo.data && brokerInfo.data.getBrokerInfoByHost) {
    store.setBaseUrl(brokerInfo.data.getBrokerInfoByHost.baseUrl || '/');
    dispatch({ type: 'SET_BASE_URL', payload: brokerInfo.data.getBrokerInfoByHost.baseUrl || '/' });
    store.setAssetsByObject(brokerInfo.data.getBrokerInfoByHost);
    dispatch({ type: 'SET_ASSETS', payload: brokerInfo.data.getBrokerInfoByHost });
  }

  if (!store.baseUrl)
    return (
      <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
        <Loader hAlign='center' vAlign='center' />
      </Box>
    );

  return (
    <>
      <JwtChecker />
      <AppSnackbar />
      <Router basename={store.baseUrl}>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Navigate replace to='/dashboard' />} />
          <Route path='/dashboard' element={<PageTemplate BodyComponent={PageDashboard} />} />
          <Route path='/business-overview' element={<PageTemplate BodyComponent={PageBusinessOverview} />} />
          <Route path='/assets-management' element={<PageTemplate BodyComponent={PageAssetsManagement} />} />
          <Route path='/reports' element={<PageTemplate BodyComponent={PageReports} />} />
          <Route path='/account-manager' element={<PageTemplate BodyComponent={PageAccountManager} />} />
          <Route path='/e-learning' element={<PageTemplate BodyComponent={PageElearning} />} />
          {environment.FEATURE_FLAG_CALENDAR && <Route path='/calendar' element={<PageTemplate BodyComponent={PageCalendar} />} />}
          {environment.FEATURE_FLAG_CALENDAR && (
            <Route path='/calendar-activity' element={<PageTemplate BodyComponent={PageCalendarActivity} />} />
          )}
          {environment.FEATURE_FLAG_CALENDAR && (
            <Route path='/calendar-activity/:projectId/:activityId' element={<PageTemplate BodyComponent={PageCalendarActivity} />} />
          )}
          <Route path='/login' element={<PageLogin />} />
          <Route path='*' element={<Navigate replace to='/dashboard' />} />
        </Routes>

        <AuthLogout />
      </Router>
    </>
  );
});

export default App;
