import { memo } from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import { useTranslation } from '@tecma/i18n';

import { useStoreMobx } from 'mobx/helpers';

const LogoutMenuButton = () => {
  const store = useStoreMobx();
  const { t } = useTranslation();

  const logOutHandler = () => store.forceLogout(true);

  return (
    <ButtonBase disableRipple className='user-menu-button' onClick={logOutHandler}>
      {t('iTd.navbar.sign')}
    </ButtonBase>
  );
};

export default memo(LogoutMenuButton);
