import { createAsyncThunk } from '@reduxjs/toolkit';
import { initI18n } from '@tecma/i18n';

import translation from '../../i18n/translation/en/translation.json';

export const thunkI18n = createAsyncThunk('i18n/INIT', async (bucketBase: string, thunkAPI) => {
  try {
    const i18n = await initI18n(translation, {
      bucketBase,
      productName: 'iTd',
      apiBase: '',
      expirationTime: 1000 * 60,
      cache: 'no-cache',
      debug: false,
    });
    return thunkAPI.fulfillWithValue(i18n.language);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error });
  }
});
