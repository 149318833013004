import React, { useRef, useState } from 'react';

import { ButtonBase, Drawer, Popover, Slide } from '@mui/material';
import { Icon } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactSVG } from 'react-svg';

import { useStoreMobx } from 'mobx/helpers';

import logoTecma from '../icons/logoTecma.svg';
import Navbar from './Navbar';
import LogoutMenuButton from './Navbar.Logout';
import SupportMenuButton from './Navbar.Support';
import './Navbar.scss';

interface Props {
  handleChangeLanguage: (lang?: string) => void;
}

const NavbarMobile = observer(({ handleChangeLanguage }: Props) => {
  const store = useStoreMobx();
  const { t, i18n } = useTranslation();
  const userButtonAnchorRef = useRef(null);
  const popoverRef = useRef(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(true);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  const handleOpenDrawer = () => setIsDrawerOpen((el) => !el);

  const handlePopoverChange = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <>
      <div className='header-mobile'>
        <ButtonBase disableRipple className='header-mobile-menu-button' onClick={handleOpenDrawer}>
          <Icon className='header-mobile-menu-button-icon' iconName='menu' />
        </ButtonBase>
        <div className='header-mobile-logo'>
          <ReactSVG src={logoTecma} />
        </div>
        <ButtonBase disableRipple className='header-mobile-menu-button' onClick={handlePopoverChange} ref={userButtonAnchorRef}>
          <Icon className='header-mobile-menu-button-icon' iconName='user-circle' />
        </ButtonBase>
        <Popover
          classes={{ root: 'user-mobile-menu' }}
          open={isPopoverOpen}
          anchorEl={userButtonAnchorRef.current}
          onClose={handlePopoverChange}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          ref={popoverRef}
        >
          <Slide
            direction='right'
            in={showUserMenu}
            container={popoverRef.current}
            appear={false}
            mountOnEnter
            unmountOnExit
            onExited={() => {
              setShowLanguageMenu(true);
            }}
          >
            <div>
              <ButtonBase
                className='user-menu-button mobile-menu-button-withIcon'
                disableRipple
                onClick={() => {
                  setShowUserMenu(false);
                }}
              >
                <div>
                  {t('iTd.navbar.Language')}
                  <Icon iconName='chevron-right' className='mobile-menu-button-icon isRightSide' />
                </div>
              </ButtonBase>
              <SupportMenuButton />
              <LogoutMenuButton />
            </div>
          </Slide>
          <Slide
            direction='left'
            in={showLanguageMenu}
            container={popoverRef.current}
            mountOnEnter
            unmountOnExit
            onExited={() => {
              setShowUserMenu(true);
            }}
          >
            <div className='user-language-mobile-menu'>
              <ButtonBase
                className='user-menu-button underlined mobile-menu-button-withIcon'
                disableRipple
                onClick={() => {
                  setShowLanguageMenu(false);
                }}
              >
                <div>
                  <Icon iconName='chevron-left' className='mobile-menu-button-icon isLeftSide' /> {t(`iTd.navbar.back`)}
                </div>
              </ButtonBase>
              {store.languages?.map((f) => {
                return (
                  <ButtonBase
                    key={f}
                    disableRipple
                    className={classNames('user-menu-button', i18n?.language === f && 'current-language')}
                    onClick={() => {
                      handleChangeLanguage(f);
                      handlePopoverChange();
                      setShowLanguageMenu(false);
                    }}
                  >
                    {t(`iTd.switch.${f}`)}
                  </ButtonBase>
                );
              })}
            </div>
          </Slide>
        </Popover>
      </div>
      <Drawer open={isDrawerOpen} onClose={handleOpenDrawer}>
        <Navbar handleChangeLanguage={handleChangeLanguage} mobileMode handleCloseDrawer={handleOpenDrawer} />
      </Drawer>
    </>
  );
});

export default NavbarMobile;
