const convertLanguage = (lang: string | undefined, locale: string | undefined) => {
  if (lang && locale) {
    return `${lang}-${locale}`;
  }
  if (lang === 'it') return 'it-IT';
  if (lang === 'en') return 'en-GB';
  return lang;
};

export default convertLanguage;
