import { createAsyncThunk } from '@reduxjs/toolkit';

import { setEnvironment } from '../../constants/environment';
import { SchemaEnvironment } from '../../schema/environment';

export const thunkEnvironment = createAsyncThunk('environment/thunk', async (_, thunkAPI) => {
  try {
    const response = await fetch('/config.json');
    const config = await response.json();
    const validate = SchemaEnvironment.safeParse(config);

    if (!validate.success) console.warn(validate.error);
    setEnvironment(config);

    return thunkAPI.fulfillWithValue(config);
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to load environment');
  }
});
