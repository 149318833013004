import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CalendarEventInput } from '../declarations';

// #region ::: QUERY :::
export interface UpdateCalendarEventInput {
  projectId: string;
  id: string;
  mailUpdate: boolean;
  input: CalendarEventInput;
}

const UPDATE_CALENDAR_EVENT = gql`
  mutation updateCalendars($project_id: ID!, $id: ID!, $mailUpdate: Boolean, $input: CalendarInput!) {
    updateCalendars(project_id: $project_id, id: $id, mailUpdate: $mailUpdate, input: $input) {
      id
    }
  }
`;
// #endregion

interface Params {
  data: UpdateCalendarEventInput;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkCalendarUpdate = createAsyncThunk('calendar/update', async ({ data, GraphQL }: Params, thunkAPI) => {
  try {
    const { projectId, id, mailUpdate, input } = data;
    const response = await GraphQL.mutate({
      mutation: UPDATE_CALENDAR_EVENT,
      variables: {
        project_id: projectId,
        id,
        mailUpdate,
        input,
      },
    });
    if (response.data.updateCalendars) {
      return thunkAPI.fulfillWithValue(response.data.updateCalendars);
    }
    throw new Error('General Calendar Error');
  } catch (error) {
    return thunkAPI.rejectWithValue('General Calendar Error');
  }
});
