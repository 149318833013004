import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

// #region ::: QUERY :::
const DELETE_CALENDAR_EVENT = gql`
  mutation deleteCalendars($project_id: ID!, $id: ID!) {
    deleteCalendars(project_id: $project_id, id: $id)
  }
`;
// #endregion

interface Params {
  projectId: string;
  id: string;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkCalendarDelete = createAsyncThunk('calendar/delete', async ({ projectId: project_id, id, GraphQL }: Params, thunkAPI) => {
  try {
    const response = await GraphQL.mutate({
      mutation: DELETE_CALENDAR_EVENT,
      variables: { project_id, id },
    });
    if (response.data.deleteCalendars) return thunkAPI.fulfillWithValue(response.data.deleteCalendars);

    throw new Error('Calendar Activity Delete Error');
  } catch (error) {
    return thunkAPI.rejectWithValue('Calendar Activity Delete Error');
  }
});
