import { createSlice } from '@reduxjs/toolkit';

import { thunkI18nCalendar } from '../i18n/i18n.thunk';

export const sliceI18nCalendar = createSlice({
  name: 'i18nCalendar',
  initialState: {
    loaded: false,
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkI18nCalendar.fulfilled, (state) => {
      state.loaded = true;
    });
  },
  selectors: {
    loaded: (state) => state.loaded,
  },
});
