import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Accordion, AccordionDetails, AccordionSummary, Popover, Tooltip } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { Icon } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { SubRoute } from 'helpers/get-navbar-items';

// Optional Props
interface NavbarGroupLinkItemProps {
  titleLabel?: string;
  icon?: string;
  subRoutes?: SubRoute[];
  collapsedMode?: boolean;
  stickToBottom?: boolean;
}

const NavbarGroupLinkItem: React.FC<NavbarGroupLinkItemProps> = ({ titleLabel, icon, subRoutes, collapsedMode, stickToBottom }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const { t } = useTranslation();

  const titleTranslated = t(`iTd.navbar.${titleLabel}`);

  const handleAccordionChange = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [collapsedMode]);

  return (
    <>
      <Accordion expanded={!collapsedMode && isOpen} disableGutters onChange={handleAccordionChange}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Tooltip
            classes={{ tooltip: 'navbar-item-tooltip' }}
            disableFocusListener
            disableTouchListener
            disableHoverListener={!collapsedMode}
            title={titleTranslated}
            placement='right'
          >
            <AccordionSummary
              classes={{
                root: `navbar-item item-expandable ${stickToBottom ? 'bottom' : ''}`,
              }}
              ref={anchorRef}
            >
              {icon && <Icon className='navbar-icon' iconName={icon} />}
              <div className='title'>{titleTranslated}</div>
              <Icon className='navbar-arrow' size='small' iconName='chevron-down' style={isOpen ? { transform: 'rotate(180deg)' } : {}} />
            </AccordionSummary>
          </Tooltip>
        </ClickAwayListener>
        <AccordionDetails>
          {subRoutes?.map((route) => (
            <div key={route?.titleLabel} className='navbar-item link subitem'>
              <ButtonBase disableRipple onClick={() => window.open(route?.to, '_blank')} className='navbar-item subitem'>
                <div className='title'>{t(`iTd.navbar.${route?.titleLabel}`)}</div>
                <Icon className='icon' size='small' iconName='external-link' />
              </ButtonBase>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
      <Popover
        classes={{ root: 'user-menu' }}
        open={!!(collapsedMode && isOpen)}
        anchorEl={anchorRef.current}
        onClose={handleAccordionChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {subRoutes?.map((route) => (
          <div key={route?.titleLabel} className='navbar-item link subitem'>
            <ButtonBase disableRipple onClick={() => window.open(route?.to, '_blank')} className='navbar-item subitem'>
              <div className='title'>{t(`iTd.navbar.${route?.titleLabel}`)}</div>
              <Icon className='icon' size='extra-small' iconName='external-link' />
            </ButtonBase>
          </div>
        ))}
      </Popover>
    </>
  );
};

export default React.memo(NavbarGroupLinkItem);
