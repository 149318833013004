import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

// #region ::: QUERY :::
interface CorporateProjectsFilter {
  projectId: string;
  filter: {
    projectIds: string[];
  };
}

const query = gql`
  query getProjectsCorporate($projectId: ID!, $filter: CorporateProjectsFilter!, $params: PaginatedProjectsParams!) {
    getProjectsCorporate(projectId: $projectId, filter: $filter, params: $params) {
      data {
        id
        displayName
        isCommercialDemo
      }
    }
  }
`;

export interface ResponseQueryCalendarsCorporate {
  getProjectsCorporate: {
    data: {
      id: string;
      displayName: string;
      isCommercialDemo: boolean;
    }[];
  };
}
// #endregion

interface Params {
  projectId: string;
  filter?: CorporateProjectsFilter;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkAssetList = createAsyncThunk('asset/list', async ({ projectId, filter, GraphQL }: Params, thunkAPI) => {
  try {
    const response = await GraphQL.query({
      query,
      variables: { projectId, filter: filter || {}, params: { sortField: 'DISPLAY_NAME', sortOrder: 1 } },
    });
    return thunkAPI.fulfillWithValue(response.data.getProjectsCorporate);
  } catch (error) {
    return thunkAPI.rejectWithValue('General Asset List Error');
  }
});
