// src/@module-calendar/redux/thunks/thunk.calendar.list.ts
import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActivityType } from '../declarations';

// #region ::: QUERY :::
interface CorporateCalendarFilter {
  projectIds: string[];
  startDate: string;
  endDate: string;
  activityType?: string[];
  status?: string[];
  vendorIds?: string[];
  clientIds?: string[];
  updatedAfter?: string;
}

const query = gql`
  query getCalendarsCorporate($projectId: ID!, $filter: CorporateCalendarFilter) {
    getCalendarsCorporate(projectId: $projectId, filter: $filter) {
      data {
        id
        projectId
        status
        startDate
        endDate
        activityType
        client {
          id
          firstName
          lastName
          email
          tel
          note
        }
        backOfficeUser {
          id
          firstName
          lastName
          email
          role
          isDisabled
        }
      }
    }
  }
`;

export interface ResponseQueryCalendarsCorporate {
  getCalendarsCorporate: {
    data: {
      id: string;
      projectId: string;
      status: 'pending' | 'lowReliability' | 'confirmed' | 'canceled';
      startDate: string;
      endDate: string;
      activityType: ActivityType;
      client: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        tel: string;
        note?: string;
      } | null;
      backOfficeUser: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        role: string;
        isDisabled: boolean;
      };
    }[];
  };
}
// #endregion

interface Params {
  projectId: string;
  filter?: CorporateCalendarFilter;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkCalendarList = createAsyncThunk('calendar/list', async ({ projectId, filter, GraphQL }: Params, thunkAPI) => {
  try {
    const response = await GraphQL.query({
      query,
      variables: { projectId, filter },
    });
    return thunkAPI.fulfillWithValue(response.data.getCalendarsCorporate);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('General Calendar Error');
  }
});
