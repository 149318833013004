import TagManager from '@tecma/analytics';

export interface Ids {
  gtmId?: string;
}

const inizializeAnalytics = ({ gtmId }: Ids) => {
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
};

export default inizializeAnalytics;
