import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';
import { Provider } from 'react-redux';

import { reduxStore } from '../redux/store';

interface Props {
  children: ReactNode;
}

export const ProviderRedux = observer(({ children }: Props) => {
  return <Provider store={reduxStore}>{children}</Provider>;
});
