import { createContext, useContext } from 'react';

import Store from 'mobx/store';

const store = new Store();

export const StoreContext = createContext(store);

export const ProviderMobx = StoreContext.Provider;

export const useStoreMobx = () => useContext(StoreContext);
