/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from 'redux/hooks';

import { ACCOUNT_MANAGER_ROLE, ADMIN_ROLE, FRONT_OFFICE_ROLE, VENDOR_MANAGER_ROLE, VENDOR_ROLE } from 'constants/role';

import { getBrokerUrl } from './get-project-urls';

import type { Role } from 'declarations/role';

export type SubRoute = {
  titleLabel: string;
  to?: string;
};

export interface NavbarItem {
  icon: string;
  titleLabel: string;
  type: string;
  stickToBottom?: boolean;
  hideMobile?: boolean;
  subRoutes?: SubRoute[];
  to?: string;
  availableToRoles?: Role[];
}

export interface MobxStoreProps {
  brokers: any;
  brokerId?: string;
  iTdConfig?: {
    emotionsUrl?: string;
  };
}

export function useNavbarItems(mobxStore: MobxStoreProps): NavbarItem[] {
  const { brokers, brokerId, iTdConfig } = mobxStore;

  const FEATURE_FLAG_CALENDAR = useAppSelector((state) => state.environment.FEATURE_FLAG_CALENDAR);

  const subRoutes: SubRoute[] = [
    {
      titleLabel: 'recommerce',
      to: brokers && brokerId ? getBrokerUrl(brokers, brokerId) : '',
    },
  ];

  if (iTdConfig?.emotionsUrl) {
    subRoutes.push({
      titleLabel: 'emotions',
      to: iTdConfig.emotionsUrl,
    });
  }

  const calendarObjRoute = {
    titleLabel: 'calendar',
    icon: 'calendar',
    type: 'link',
    to: '/calendar',
    stickToBottom: false,
    availableToRoles: [ADMIN_ROLE, VENDOR_MANAGER_ROLE, VENDOR_ROLE, FRONT_OFFICE_ROLE],
  };

  const baseOptions: Array<NavbarItem> = [
    {
      titleLabel: 'dashboard',
      icon: 'template',
      type: 'link',
      to: '/dashboard',
      stickToBottom: false,
    },
    {
      titleLabel: 'businessOverview',
      icon: 'presentation-chart-line',
      type: 'link',
      to: '/business-overview',
      stickToBottom: false,
    },
    {
      titleLabel: 'assetsManagement',
      icon: 'office-building',
      type: 'link',
      to: '/assets-management',
      stickToBottom: false,
    },
    {
      titleLabel: 'enterprise',
      icon: 'view-grid',
      type: 'routeGroup',
      subRoutes,
    },
    {
      titleLabel: 'reports',
      icon: 'chart-square-bar',
      type: 'link',
      to: '/reports',
      stickToBottom: false,
    },
    {
      titleLabel: 'accountManager',
      icon: 'users',
      type: 'link',
      to: '/account-manager',
      stickToBottom: false,
      availableToRoles: [ADMIN_ROLE, ACCOUNT_MANAGER_ROLE],
    },
    ...(FEATURE_FLAG_CALENDAR ? [calendarObjRoute] : []),
    {
      titleLabel: 'elearning',
      icon: 'bookmark-alt',
      type: 'link',
      to: '/e-learning',
      stickToBottom: true,
    },
    {
      titleLabel: 'user',
      icon: 'user-circle',
      type: 'user',
      stickToBottom: true,
      hideMobile: true,
    },
  ];

  return baseOptions;
}

export default useNavbarItems;
