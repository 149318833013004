import { ApolloError, gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { FormActivityFields, GraphQLError } from '../declarations';
import { utilityGetDateLocalISOString } from '../helpers/getLocalDateISOString';

// #region ::: QUERY :::
const GET_CALENDAR_EVENT = gql`
  query getCalendar($project_id: ID!, $id: ID!) {
    getCalendar(project_id: $project_id, id: $id) {
      id
      startDate
      endDate
      activityType
      address
      videoCallLink
      meetingNumber
      client {
        id
        firstName
        lastName
        email
        tel
      }
      vendor {
        id
        firstName
        lastName
        email
        isDisabled
      }
      note
      infoText
    }
  }
`;
// #endregion

interface Params {
  projectId: string;
  activityId: string;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkCalendarDetails = createAsyncThunk<
  FormActivityFields,
  Params,
  {
    rejectValue: GraphQLError;
  }
>('calendar/details', async ({ projectId: project_id, activityId: id, GraphQL }, thunkAPI) => {
  try {
    const response = await GraphQL.query({
      query: GET_CALENDAR_EVENT,
      variables: { project_id, id },
    });
    if (response.data.getCalendar) {
      const { address, note, infoText, vendor, client, activityType, startDate, endDate, ...calendarData } = response.data.getCalendar;

      const calendarActivityDetails: FormActivityFields = {
        ...calendarData,
        asset: {
          value: project_id,
          label: 'Test Asset',
        },
        activityType: {
          value: activityType,
          label: activityType,
        },
        ...(vendor && {
          vendor: {
            value: vendor.id,
            label: `${vendor.firstName} ${vendor.lastName}`,
            disabled: vendor.isDisabled,
          },
        }),
        ...(client && {
          client: {
            value: client.id,
            label: `${client.firstName} ${client.lastName}`,
            email: client.email,
            tel: client.tel,
          },
        }),
        date: utilityGetDateLocalISOString(moment(startDate)),
        startTime: utilityGetDateLocalISOString(moment(startDate)),
        endTime: utilityGetDateLocalISOString(moment(endDate)),
        location: address,
        internalNotes: note,
        activityNotes: infoText,
      };
      return thunkAPI.fulfillWithValue(calendarActivityDetails);
    }
    throw new Error(response.errors?.[0]?.message);
  } catch (error) {
    return thunkAPI.rejectWithValue((error as ApolloError)?.graphQLErrors[0] as GraphQLError);
  }
});
