import { memo } from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import { useTranslation } from '@tecma/i18n';

import { useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';

const SupportMenuButton = () => {
  const { t } = useTranslation();
  const environment = useAppSelector(sliceEnvironment.selectors.environment);

  return (
    <ButtonBase
      className='user-menu-button'
      disableRipple
      onClick={(e) => {
        window.location.href = `mailto:${environment.REACT_APP_INFO_MAIL}`;
        e.preventDefault();
      }}
    >
      {t('iTd.navbar.support')}
    </ButtonBase>
  );
};

export default memo(SupportMenuButton);
