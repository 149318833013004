import { configureStore } from '@reduxjs/toolkit';

import { sliceCalendar } from '../@module-calendar/slice/slice.calendar';
import { sliceI18nCalendar } from '../@module-calendar/slice/slice.calendar.i18n';
import { sliceDialogs } from '../@module-calendar/slice/slice.dialog';
import { sliceEnvironment } from './slice/slice.environment';
import { sliceI18n } from './slice/slice.i18n';

export const reduxStore = configureStore({
  reducer: {
    environment: sliceEnvironment.reducer,
    i18n: sliceI18n.reducer,
    calendar: sliceCalendar.reducer,
    dialogs: sliceDialogs.reducer,
    i18nCalendar: sliceI18nCalendar.reducer,
  },
});
