import { ReactNode, useEffect } from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react';

import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';
import { thunkEnvironment } from '../redux/thunks/thunk.environment';
import Loader from './Loader';

interface Props {
  children: ReactNode;
}

export const ProviderEnvironment = observer(({ children }: Props) => {
  const environment = useAppSelector(sliceEnvironment.selectors.environment);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(thunkEnvironment());
  }, []);

  if (!environment.REACT_APP_APOLLO_URI)
    return (
      <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
        <Loader hAlign='center' vAlign='center' />
      </Box>
    );

  return <>{children}</>;
});
