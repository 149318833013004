import React, { Suspense, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from '@tecma/i18n';
import { logger } from '@tecma/logs-fe';
import classNames from 'classnames';
import moment from 'moment/moment';

import convertLanguage from 'helpers/convert-language';
import getOrderedLanguages from 'helpers/get-ordered-language';
import { useStoreMobx } from 'mobx/helpers';

import Client from 'constants/queries';

import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/de';
import Navbar from './Navbar';
import NavbarMobile from './Navbar.Mobile';

import './Page.Template.scss';
import Loader from './Loader';

// Optional Props
interface Props {
  BodyComponent?: any;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  'data-testid'?: string;
}

const TemplatePage = ({ className, BodyComponent, ...rest }: Props) => {
  const store = useStoreMobx();
  const classList = classNames('tecma-templatePage', className);
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const { i18n } = useTranslation();

  const useMobileNavbar = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [newLang, setNewLang] = useState('');

  useEffect(() => {
    moment.locale(newLang);
  }, [newLang]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderedLanguages, setOrderedLanguages] = useState(
    getOrderedLanguages(store.configLanguages, convertLanguage(store.loggedUser?.language, store.loggedUser?.locale)),
  );

  const setLanguage = (lang: string, locale: string) => {
    setNewLang(lang);
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    const updateData = {
      TwoFA: store.loggedUser?.TwoFA,
      email: store.loggedUser?.email,
      firstName: store.loggedUser?.firstName,
      hasGoogleToken: store.loggedUser?.hasGoogleToken,
      hasMicrosoftToken: store.loggedUser?.hasMicrosoftToken,
      language: lang,
      lastName: store.loggedUser?.lastName,
      locale,
      project_ids: store.loggedUser?.project_ids,
      role: store.loggedUser?.role,
      vendorColor: store.loggedUser?.vendorColor,
    };
    updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser?.id, updateData))
      .then(() => {
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
        setNewLang(lang);
      })
      .catch((e) => {
        if (oldLanguage) {
          setNewLang(oldLanguage);
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
          setNewLang(oldLanguage);
        }
        logger.error(e);
      });
  };

  const handleChangeLanguage = (selectedLanguage: string | undefined) => {
    if (selectedLanguage) {
      setOrderedLanguages(getOrderedLanguages(store.configLanguages, selectedLanguage));
      const trueLanguage = selectedLanguage.split('-')[0];
      const locale = selectedLanguage.split('-')[1];
      setLanguage(trueLanguage, locale);
    }
  };

  return (
    <div className={classList} data-testid='tecma-templatePage' {...rest}>
      {useMobileNavbar ? (
        <NavbarMobile handleChangeLanguage={handleChangeLanguage} />
      ) : (
        <Navbar handleChangeLanguage={handleChangeLanguage} />
      )}

      <div id='body-container' className='body-container'>
        <Suspense
          fallback={
            <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              <Loader hAlign='center' vAlign='center' />
            </Box>
          }
        >
          <BodyComponent />
        </Suspense>
      </div>
    </div>
  );
};

export default React.memo(TemplatePage);
