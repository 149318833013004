import i18n, { initI18n, loadResources } from '@tecma/i18n';

import { TLocale } from './i18n.declarations';

interface Params {
  projectId: string;
  languages: Array<TLocale>;
  translations: Record<string, unknown>;
  moduleName: string;
  projectName?: string;
  bucketBaseUrl: string;
}

const loadTranslation = async (bucketBaseUrl: string, productName: string, language: TLocale) => {
  const response = await fetch(`${bucketBaseUrl}/${encodeURI(productName)}/i18n/${language}/translation.json`);
  const translations = await response.json();
  i18n.addResourceBundle(language, 'translation', translations, true, true);
};

const loadCustomTranslation = async (bucketBaseUrl: string, productName: string, language: TLocale, projectName: string) => {
  const response = await fetch(
    `${bucketBaseUrl}/initiatives/${encodeURI(projectName)}/${encodeURI(productName)}/i18n/${language}/translation.json`,
  );
  const translations = await response.json();
  i18n.addResourceBundle(language, 'translation', translations, true, true);
};

const loadTranslationsBundle = async (bucketBaseUrl: string, languages: TLocale[], productName: string, projectName?: string) => {
  const localLanguages = languages
    // First load the current language
    .sort((lng) => (i18n.language !== lng ? 1 : -1));
  // eslint-disable-next-line no-restricted-syntax
  for (const language of localLanguages) {
    // eslint-disable-next-line no-await-in-loop
    await loadTranslation(bucketBaseUrl, productName, language);
    if (projectName) {
      // eslint-disable-next-line no-await-in-loop
      await loadCustomTranslation(bucketBaseUrl, productName, language, projectName);
    }
    // If I'm loading the current language force refresh
    if (i18n.language === language) {
      i18n.changeLanguage(language);
    }
  }
  if (!languages.includes(i18n.language as TLocale)) {
    const formattedLanguage = languages.find((lang) => lang.startsWith(i18n.language));
    i18n.changeLanguage(formattedLanguage);
  }
};

export const loadI18n = async ({ bucketBaseUrl, projectId, languages, translations, moduleName, projectName }: Params) => {
  if (!i18n.isInitialized) {
    await initI18n(
      translations,
      {
        bucketBase: bucketBaseUrl,
        productName: `Modules/${moduleName}`,
        apiBase: '',
        debug: false,
      },
      'en-GB',
    );
    await loadResources({
      id: projectId,
      displayName: projectName ?? '',
      languages,
    });
  } else {
    await loadTranslationsBundle(bucketBaseUrl, languages, `Modules/${moduleName}`, projectName);
  }
};
