import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

// #region ::: QUERY :::
const GET_CLIENTS = gql`
  query getClientsCorporate($projectId: ID!, $params: PaginatedClientsParams!, $filter: CorporateClientsFilter!) {
    getClientsCorporate(projectId: $projectId, params: $params, filter: $filter) {
      data {
        id
        firstName
        lastName
        email
        tel
        note
        project_id
      }
      paginationInfo {
        totalDocs
        page
        perPage
        totalPages
        prevPage
        nextPage
      }
    }
  }
`;

type SortField = 'CREATED_ON' | 'UPDATED_ON' | 'EMAIL' | 'FULL_NAME' | 'FIRST_NAME' | 'LAST_NAME';

export interface PaginatedClientsParams {
  page: number;
  perPage?: number;
  searchText?: string;
  sortField?: SortField;
  sortOrder?: number;
}

export interface ClientListParams {
  params: PaginatedClientsParams;
  filter: {
    projectIds: string[];
  };
}
// #endregion

export interface CorporateClientsFilter {
  projectIds?: string[];
}

export enum ClientsInfoSortFields {
  CREATED_ON = 'CREATED_ON',
  UPDATED_ON = 'UPDATED_ON',
  EMAIL = 'EMAIL',
  FULL_NAME = 'FULL_NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
}

export const thunkGetClients = createAsyncThunk(
  'client/list',
  async ({ params, filter, GraphQL }: ClientListParams & { GraphQL: ReturnType<typeof useApolloClient> }, thunkAPI) => {
    if (!params.perPage) {
      params.perPage = 10;
    }
    try {
      // @ts-ignore
      const { demoProjectId, clients } = thunkAPI.getState().calendar;
      const response = await GraphQL.query({
        query: GET_CLIENTS,
        variables: {
          projectId: demoProjectId,
          params: { ...params, sortField: params.sortField || 'FULL_NAME', sortOrder: params.sortOrder || 1 },
          filter,
        },
      });
      if (response.data.getClientsCorporate) {
        const clientsList = response.data.getClientsCorporate;
        const hasMore = clientsList.paginationInfo.totalPages > params.page;
        if (params.page > 1) {
          const existingClientIds = new Set(clients.map((client) => client.id));
          const newClients = response.data.getClientsCorporate.data.filter((client) => !existingClientIds.has(client.id));
          return thunkAPI.fulfillWithValue({ clients: [...clients, ...newClients], hasMore, pagination: clientsList.paginationInfo });
        }
        return thunkAPI.fulfillWithValue({
          clients: response.data.getClientsCorporate.data,
          hasMore,
          pagination: clientsList.paginationInfo,
        });
      }
      throw new Error('General Calendar Error');
    } catch (error) {
      return thunkAPI.rejectWithValue('General Calendar Error');
    }
  },
);

export const fetchClientsWithoutRedux = async (
  projectId: string,
  filter: CorporateClientsFilter,
  GraphQL: ReturnType<typeof useApolloClient>,
  params?: PaginatedClientsParams,
) => {
  try {
    // const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({
      query: GET_CLIENTS,
      variables: {
        projectId,
        params: params || { page: 1, perPage: 10, sortField: 'FULL_NAME', sortOrder: 1, searchText: '' },
        filter: filter || { projectIds: [] },
      },
    });

    return response.data.getClientsCorporate;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw new Error('Error fetching clients');
  }
};
