/* eslint-disable react/jsx-props-no-spreading */

import { observer } from 'mobx-react';

import { useNavbarItems } from 'helpers/get-navbar-items';
import { useStoreMobx } from 'mobx/helpers';

import NavbarGroupLinkItem from 'components/Navbar.Group';
import NavbarLinkItem from 'components/Navbar.Item';
import NavbarUserItem from 'components/Navbar.User';

import type { Role } from 'declarations/role';

import './Navbar.Items.scss';

interface Props {
  mobileMode: boolean;
  collapsedMode?: boolean;
  handleChangeLanguage: (lang?: string) => void;
}

const NavbarItems = observer(({ mobileMode, collapsedMode, handleChangeLanguage }: Props) => {
  const store = useStoreMobx();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rawNavbarItems = useNavbarItems(store as any);

  const navbarItems = rawNavbarItems.filter((item) => {
    if (!item.availableToRoles) {
      return true;
    }
    return item.availableToRoles.includes(store.loggedUser?.role as Role);
  });

  return (
    <div className='navbar-routes'>
      {navbarItems.map((navbarItem) => {
        if (navbarItem.type === 'routeGroup') {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <NavbarGroupLinkItem key={navbarItem.titleLabel} {...navbarItem} collapsedMode={collapsedMode} />;
        }
        if (navbarItem.type === 'user' && !mobileMode) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return (
            <NavbarUserItem
              key={navbarItem.titleLabel}
              {...navbarItem}
              handleChangeLanguage={handleChangeLanguage}
              collapsedMode={collapsedMode}
            />
          );
        }
        if (navbarItem.type === 'link') {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <NavbarLinkItem key={navbarItem.titleLabel} {...navbarItem} showTooltip={collapsedMode} />;
        }
        return null;
      })}
    </div>
  );
});

export default NavbarItems;
