import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CalendarEventInput } from '../declarations';

// #region ::: QUERY :::
const CREATE_CALENDAR_EVENT = gql`
  mutation createCalendars($project_id: ID!, $input: CalendarInput!) {
    createCalendars(project_id: $project_id, input: $input) {
      id
    }
  }
`;
// #endregion

interface Params {
  projectId: string;
  input: CalendarEventInput;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkCalendarCreate = createAsyncThunk(
  'calendar/create',
  async ({ projectId: project_id, input, GraphQL }: Params, thunkAPI) => {
    try {
      const response = await GraphQL.mutate({
        mutation: CREATE_CALENDAR_EVENT,
        variables: { project_id, input },
      });
      if (response.data.createCalendars) return thunkAPI.fulfillWithValue(response.data.createCalendars);

      throw new Error('General Calendar Error');
    } catch (error) {
      return thunkAPI.rejectWithValue('General Calendar Error');
    }
  },
);
