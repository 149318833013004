import { createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';

import { SchemaEnvironment } from '../../schema/environment';
import { thunkEnvironment } from '../thunks/thunk.environment';

export type TEnvironment = z.infer<typeof SchemaEnvironment>;

interface State extends TEnvironment {
  loading?: boolean;
}

export const sliceEnvironment = createSlice({
  initialState: {} as State,
  name: 'environment',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkEnvironment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(thunkEnvironment.fulfilled, (state, action) => {
      return action.payload;
    });
  },
  selectors: {
    environment: (state) => state,
    isLoading: (state) => state.loading || false,
  },
});
