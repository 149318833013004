import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

import { ADMIN_ROLE, VENDOR_ROLE } from 'constants/role';

import { Asset, CalendarEvent, Client, FormActivityFields, LoggedUser, Pagination, Vendor } from '../declarations';
import { utilityGetDateLocalISOString } from '../helpers/getLocalDateISOString';
import { thunkAssetList } from '../thunks/thunk.asset.list';
import { thunkCalendarCreate } from '../thunks/thunk.calendar.create';
import { thunkCalendarDelete } from '../thunks/thunk.calendar.delete';
import { thunkCalendarDetails } from '../thunks/thunk.calendar.details';
import { thunkCalendarList } from '../thunks/thunk.calendar.list';
import { thunkCalendarUpdate } from '../thunks/thunk.calendar.update';
import { thunkGetClients } from '../thunks/thunk.client.list';
import { thunkGetVendors } from '../thunks/thunk.vendor.list';

const getInitialDate = () => {
  const persistedDateStr = sessionStorage.getItem('calendarCurrentDate');
  const initialDate = persistedDateStr ? moment(persistedDateStr).clone().locale('it') : undefined;
  return initialDate;
};

const getInitialFilters = () => {
  const persistedFilters = localStorage.getItem('filters');
  return persistedFilters ? JSON.parse(persistedFilters) : { assets: [], vendors: [], clients: [] };
};

export const sliceCalendar = createSlice({
  name: 'calendar',
  initialState: {
    currentDate: getInitialDate(),
    appliedFilters: getInitialFilters(),
    view: 'week',
  } as {
    brokerId: string;
    demoProjectId: string;
    assets: Asset[];
    vendors: Vendor[];
    clients: Client[];
    pagination: Pagination;
    loggedUser: LoggedUser;
    loadingForm?: boolean;
    loadingVendors?: boolean;
    selectedEvent?: FormActivityFields;
    events: CalendarEvent[];
    view: 'day' | 'week' | 'month';
    currentDate: Moment;
    startDate?: string;
    endDate?: string;
    loading?: true;
    hasMoreClients?: boolean;
    loadingClients?: boolean;
    isUnauthorized?: boolean;
    appliedFilters: {
      assets: Array<{ id: string; label: string }>;
      vendors: Array<{ id: string; label: string }>;
      clients: Array<{ id: string; label: string }>;
    };
  },
  reducers: {
    setBrokerId: (state, action: PayloadAction<string>) => {
      state.brokerId = action.payload;
    },
    setCurrentDate: (state, action: PayloadAction<Moment>) => {
      state.currentDate = action.payload;
    },
    setView: (state, action: PayloadAction<'day' | 'week' | 'month'>) => {
      state.view = action.payload;
    },
    setDemoProjectId: (state, action: PayloadAction<string>) => {
      state.demoProjectId = action.payload;
    },
    setVendors: (state, action: PayloadAction<Vendor[]>) => {
      state.vendors = action.payload;
    },
    setClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    setLoggedUser: (state, action: PayloadAction<LoggedUser>) => {
      state.loggedUser = action.payload;
    },
    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setSelectedEvent: (state, action: PayloadAction<FormActivityFields | undefined>) => {
      state.selectedEvent = action.payload;
    },
    setHasMoreClients: (state, action: PayloadAction<boolean>) => {
      state.hasMoreClients = action.payload;
    },
    setAppliedFilters: (
      state,
      action: PayloadAction<{
        assets: { id: string; label: string }[];
        vendors: { id: string; label: string }[];
        clients: { id: string; label: string }[];
      }>,
    ) => {
      localStorage.setItem('filters', JSON.stringify(action.payload));
      state.appliedFilters = action.payload;
    },
    setIsUnauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
    resetFilters: (state) => {
      state.appliedFilters = { assets: [], vendors: [], clients: [] };
    },
  },
  extraReducers: (builder) => {
    // #region ::: EVENTS LIST
    builder.addCase(thunkCalendarList.pending, (state, action) => {
      state.loading = true;
      state.startDate = utilityGetDateLocalISOString(moment(action.meta.arg.filter?.startDate));
      state.endDate = utilityGetDateLocalISOString(moment(action.meta.arg.filter?.endDate));
    });
    builder.addCase(thunkCalendarList.fulfilled, (state, action) => {
      state.events = action.payload.data.map((event) => ({
        ...event,
        startDate: utilityGetDateLocalISOString(moment(event.startDate)),
        endDate: utilityGetDateLocalISOString(moment(event.endDate)),
      }));
      delete state.loading;
    });
    // #endregion
    // #region ::: CREATE ACTIVITY
    builder.addCase(thunkCalendarCreate.fulfilled, (state) => {
      delete state.loadingForm;
    });
    builder.addCase(thunkCalendarCreate.pending, (state) => {
      state.loadingForm = true;
    });
    builder.addCase(thunkCalendarCreate.rejected, (state) => {
      delete state.loadingForm;
    });
    // #endregion
    // #region ::: UPDATE ACTIVITY
    builder.addCase(thunkCalendarUpdate.fulfilled, (state) => {
      delete state.loadingForm;
    });
    builder.addCase(thunkCalendarUpdate.pending, (state) => {
      state.loadingForm = true;
    });
    builder.addCase(thunkCalendarUpdate.rejected, (state) => {
      delete state.loadingForm;
    });
    // #endregion
    // #region ::: DELETE ACTIVITY
    builder.addCase(thunkCalendarDelete.fulfilled, (state) => {
      delete state.loadingForm;
    });
    builder.addCase(thunkCalendarDelete.pending, (state) => {
      state.loadingForm = true;
    });
    builder.addCase(thunkCalendarDelete.rejected, (state) => {
      delete state.loadingForm;
    });
    // #endregion
    // #region ::: DETAILS
    builder.addCase(thunkCalendarDetails.pending, (state) => {
      state.loadingForm = true;
    });
    builder.addCase(thunkCalendarDetails.fulfilled, (state, action) => {
      state.selectedEvent = action.payload;
    });
    builder.addCase(thunkCalendarDetails.rejected, (state, action) => {
      if (action.payload?.extensions?.exception.status === 403) state.isUnauthorized = true;
      delete state.loadingForm;
    });
    // #endregion
    // #region ::: VENDORS
    builder.addCase(thunkGetVendors.fulfilled, (state, action) => {
      state.vendors = action.payload;
    });
    builder.addCase(thunkGetVendors.pending, (state) => {
      state.loadingVendors = true;
    });
    builder.addCase(thunkGetVendors.rejected, (state) => {
      delete state.loadingVendors;
    });
    // #endregion
    // #region ::: CLIENTS
    builder.addCase(thunkGetClients.pending, (state) => {
      state.loadingClients = true;
    });
    builder.addCase(thunkGetClients.fulfilled, (state, action) => {
      state.clients = action.payload.clients;
      state.pagination = action.payload.pagination;
      state.hasMoreClients = action.payload.hasMore;
      delete state.loadingClients;
    });
    builder.addCase(thunkGetClients.rejected, (state) => {
      delete state.loadingClients;
    });
    // #endregion
    // #region ::: ASSETS
    builder.addCase(thunkAssetList.fulfilled, (state, action) => {
      state.assets = action.payload.data;
    });
    // #endregion
  },
  selectors: {
    isLoading: (state) => state.loading,
    brokerId: (state) => state.brokerId,
    demoProjectId: (state) => state.demoProjectId,
    assets: (state) => state.assets,
    vendors: (state) => state.vendors,
    clients: (state) => state.clients,
    clientsPagination: (state) => state.pagination,
    loggedUser: (state) => state.loggedUser,
    currentView: (state) => state.view,
    currentDate: (state) => moment(state.currentDate).clone().locale('it'),
    assetsByLoggedUserRole: (state) => {
      if (state.loggedUser.role === ADMIN_ROLE) return state.assets;
      return state.loggedUser.project_ids.filter((project) => state.assets.find((asset) => asset.id === project));
    },
    events: (state) => {
      const { events, assets, loggedUser } = state;
      const eventsByDate = events?.reduce((record, evt) => {
        const validDateStr = evt.startDate.replace(/^(\d{4}-\d{2}-\d{2}):/, '$1T');
        const m = moment(validDateStr).locale('it');
        if (!m.isValid()) return record;
        let eventToAdd = evt;
        if (assets?.length && evt.projectId) {
          const matchingAsset = assets.find((asset) => asset.id === evt.projectId);
          if (matchingAsset) {
            eventToAdd = {
              ...eventToAdd,
              projectDisplayName: matchingAsset.displayName,
            };
          }
          if (loggedUser.role !== VENDOR_ROLE) {
            eventToAdd = {
              ...eventToAdd,
              showVendor: true,
            };
          }
        }
        const dayKey = m.format('YYYY-MM-DD');
        if (!record[dayKey]) record[dayKey] = [];
        record[dayKey].push(eventToAdd);
        return record;
      }, {});

      return eventsByDate;
    },
    filters: (state) => ({
      projectIds: state.appliedFilters?.assets.map((asset) => asset.id) || [],
      vendorIds: state.appliedFilters?.vendors.map((vendor) => vendor.id) || [],
      clientIds: state.appliedFilters?.clients.map((client) => client.id) || [],
    }),
    appliedFilters: (state) => ({
      assets: state.appliedFilters?.assets,
      vendors: state.appliedFilters?.vendors,
      clients: state.appliedFilters?.clients,
    }),
    selectedEvent: (state) => state.selectedEvent,
    loadingForm: (state) => state.loadingForm,
    hasMoreClients: (state) => state.hasMoreClients,
    loadingClients: (state) => state.loadingClients,
    isUnauthorized: (state) => state.isUnauthorized,
  },
});
