import { BrokerObject, EnabledTool } from 'mobx/store';

const getBssUrl = (project: { enabledTools: EnabledTool[] }) => {
  return project.enabledTools?.find((tool: { name: string }) => tool.name === 'BusinessPlatform')?.url;
};

const getDocsUrl = (project: { enabledTools: EnabledTool[] }) => {
  const tutorialUrl = project.enabledTools?.find((tool: { name: string }) => tool.name === 'Tutorial')?.url;
  return tutorialUrl ?? '';
};

const getBrokerUrl = (brokers: BrokerObject[], brokerId: string) => {
  return brokers.find((broker) => broker.id === brokerId)?.url;
};

export { getBssUrl, getDocsUrl, getBrokerUrl };
