import { ReactNode } from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ServerError, ServerParseError, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { observer } from 'mobx-react';

import { useStoreMobx } from '../mobx/helpers';
import { useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';

interface Props {
  children: ReactNode;
}

export const ProviderGraphQL = observer(({ children }: Props) => {
  const store = useStoreMobx();
  const environment = useAppSelector(sliceEnvironment.selectors.environment);

  const createClient = () => {
    const errorLink = onError(({ networkError, graphQLErrors, operation, response }) => {
      if (
        (networkError as ServerError)?.result?.errors[0]?.extensions?.code === 'UNAUTHENTICATED' ||
        (networkError as ServerParseError)?.statusCode === 401 ||
        (graphQLErrors && graphQLErrors[0]?.extensions?.response?.status === 403)
      ) {
        store.forceLogout(true);
      } else if (
        operation?.operationName === 'getUserByJWT' &&
        response?.data?.getUserByJWT === null &&
        response?.errors?.length &&
        response?.errors?.length > 0
      ) {
        store.forceLogout(true);
      }
    });

    const httpLink = from([
      errorLink,
      new HttpLink({
        credentials: 'include',
        uri: environment.REACT_APP_APOLLO_URI,
      }),
    ]);

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
        },
      };
    });

    return new ApolloClient({
      credentials: 'include',
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  };

  return <ApolloProvider client={createClient()}>{children}</ApolloProvider>;
});
