import { createAsyncThunk } from '@reduxjs/toolkit';

import { ENVIRONMENT } from '../../constants/environment';
import { TLocale } from './i18n.declarations';
import { loadI18n } from './i18n.helpers';

export const thunkI18nCalendar = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  {
    project_id: string;
    languages: Array<TLocale>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translations: Record<string, any>;
    projectName: string;
  }
>('i18n/module-calendar', async ({ project_id, languages, translations, projectName }, thunkAPI) => {
  try {
    await loadI18n({
      // @ts-ignore
      bucketBaseUrl: ENVIRONMENT.REACT_APP_BUCKET_BASEURL,
      projectId: project_id,
      languages,
      translations,
      moduleName: 'calendar',
      projectName,
    });
    return thunkAPI.fulfillWithValue('i18n Loaded');
  } catch (error) {
    return thunkAPI.rejectWithValue({ error });
  }
});
