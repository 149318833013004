import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import { Icon } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import { NavLink, useNavigate } from 'react-router-dom';

interface Props {
  titleLabel?: string;
  icon?: string;
  to?: string;
  showTooltip?: boolean;
  stickToBottom?: boolean;
}

const NavbarLinkItem = ({ titleLabel, icon, to, showTooltip, stickToBottom }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const titleTranslated = t(`iTd.navbar.${titleLabel}`);
  const onButtonClick = (path: string) => {
    navigate(path);
  };

  return (
    <NavLink
      to={to || ''}
      className={({ isActive, isPending }) =>
        `navbar-item ${isActive ? 'active' : isPending ? 'pending' : ''} ${stickToBottom ? 'bottom' : ''}`
      }
    >
      <Tooltip
        classes={{ tooltip: 'navbar-item-tooltip' }}
        disableFocusListener
        disableTouchListener
        disableHoverListener={!showTooltip}
        title={titleTranslated}
        placement='right'
      >
        <ButtonBase
          onClick={() => {
            if (to) {
              onButtonClick(to);
            }
          }}
          disableRipple
        >
          {icon && <Icon className='navbar-icon' iconName={icon} />}
          <div className='title'>{titleTranslated}</div>
        </ButtonBase>
      </Tooltip>
    </NavLink>
  );
};

export default React.memo(NavbarLinkItem);
