import { ReactNode, useEffect } from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';
import { sliceI18n } from '../redux/slice/slice.i18n';
import { thunkI18n } from '../redux/thunks/thunk.i18n';
import Loader from './Loader';

interface Props {
  children: ReactNode;
}

export const ProviderI18n = observer(({ children }: Props) => {
  const dispatch = useAppDispatch();
  const i18nLoaded = useAppSelector(sliceI18n.selectors.loaded);
  const environment = useAppSelector(sliceEnvironment.selectors.environment);

  useEffect(() => {
    dispatch(thunkI18n(environment.REACT_APP_BUCKET_BASEURL));
  }, [environment.REACT_APP_BUCKET_BASEURL]);

  if (!i18nLoaded)
    return (
      <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
        <Loader hAlign='center' vAlign='center' />
      </Box>
    );

  return <>{children}</>;
});
