import { ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react';

import { ProviderMobx } from '../mobx/helpers';
import Store from '../mobx/store';
import theme from '../theme/theme';
import { ProviderAnalytics } from './Provider.Analytics';
import { ProviderAuth } from './Provider.Auth';
import { ProviderEnvironment } from './Provider.Environment';
import { ProviderGraphQL } from './Provider.GraphQL';
import { ProviderI18n } from './Provider.i18n';
import { ProviderRedux } from './Provider.Redux';

const store = new Store();

interface Props {
  children: ReactNode;
}

export const Providers = observer(({ children }: Props) => (
  <ProviderRedux>
    <ProviderEnvironment>
      <ProviderI18n>
        <ProviderAnalytics>
          <ProviderMobx value={store}>
            <ProviderGraphQL>
              <ProviderAuth>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
              </ProviderAuth>
            </ProviderGraphQL>
          </ProviderMobx>
        </ProviderAnalytics>
      </ProviderI18n>
    </ProviderEnvironment>
  </ProviderRedux>
));
