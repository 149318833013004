import React, { useState, useRef } from 'react';

import { Tooltip } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Popover from '@mui/material/Popover';
import { Icon } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useStoreMobx } from 'mobx/helpers';

import LogoutMenuButton from './Navbar.Logout';
import SupportMenuButton from './Navbar.Support';

interface LanguageMenuProps {
  handleClickAwayParentMenu: () => void;
  handleChangeLanguage: (lang?: string) => void;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ handleChangeLanguage, handleClickAwayParentMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const store = useStoreMobx();
  const { t, i18n } = useTranslation();

  const handlePopoverChange = () => {
    if (isOpen) {
      handleClickAwayParentMenu();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ButtonBase disableRipple className='user-menu-button language' ref={anchorRef} onClick={handlePopoverChange}>
        {t('iTd.navbar.Language')}
        <Icon className='icon' iconName='chevron-right' />
      </ButtonBase>
      <Popover
        classes={{ root: 'user-language' }}
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={handlePopoverChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {store.languages?.map((f) => {
          return (
            <ButtonBase
              key={f}
              disableRipple
              className={classNames('user-menu-button', i18n?.language === f && 'current-language')}
              onClick={() => handleChangeLanguage(f)}
            >
              {t(`iTd.switch.${f}`)}
            </ButtonBase>
          );
        })}
      </Popover>
    </>
  );
};

interface NavbarUserItemProps {
  icon?: string;
  collapsedMode?: boolean;
  stickToBottom?: boolean;
  handleChangeLanguage: (lang?: string) => void;
}

const NavbarUserItem: React.FC<NavbarUserItemProps> = observer(({ icon, collapsedMode, stickToBottom, handleChangeLanguage }) => {
  const store = useStoreMobx();
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const userName = store.loggedUser ? `${store.loggedUser.firstName?.toLowerCase()} ${store.loggedUser.lastName?.toLowerCase()}` : '';

  const handlePopoverChange = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <Tooltip
        classes={{ tooltip: 'navbar-item-tooltip tooltip-user' }}
        disableFocusListener
        disableTouchListener
        disableHoverListener={!collapsedMode}
        title={userName}
        placement='right'
      >
        <ButtonBase
          disableRipple
          ref={anchorRef}
          className={`navbar-item item-user ${stickToBottom ? 'bottom' : ''}`}
          onClick={handlePopoverChange}
        >
          {icon && <Icon className='navbar-icon' iconName={icon} />}
          <div className='title'>{userName}</div>
          <Icon className='navbar-arrow' iconName='dots-horizontal' />
        </ButtonBase>
      </Tooltip>
      <Popover
        classes={{ root: 'user-menu' }}
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={handlePopoverChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LanguageMenu handleChangeLanguage={handleChangeLanguage} handleClickAwayParentMenu={handleClickAway} />
        <SupportMenuButton />
        <LogoutMenuButton />
      </Popover>
    </>
  );
});

export default NavbarUserItem;
