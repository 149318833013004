import { useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';

const DetectUrl = () => {
  let hostname: string | undefined =
    window.location.hostname.indexOf('www.') >= 0 ? window.location.hostname : `www.${window.location.hostname}`;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');
  const environment = useAppSelector(sliceEnvironment.selectors.environment);
  const isProd = environment.REACT_APP_NAMESPACE === 'biz-tecma-prod';

  if (!isProd) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      localStorage.clear();
      delete localStorage.ITD_DEV_HOSTNAME;
      localStorage.setItem('ITD_DEV_HOSTNAME', hostnameParam);
    }

    const ITD_DEV_HOSTNAME = localStorage.getItem('ITD_DEV_HOSTNAME');

    if (ITD_DEV_HOSTNAME && ITD_DEV_HOSTNAME.length > 0 && ITD_DEV_HOSTNAME !== '') {
      return ITD_DEV_HOSTNAME;
    }

    hostname = environment.REACT_APP_HOST;
  }

  return hostname;
};

export default DetectUrl;
