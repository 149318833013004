import React, { memo, useEffect, useState } from 'react';

import { ButtonBase, Tooltip } from '@mui/material';
import { Icon } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import logoTecma from '../icons/logoTecma.svg';
import logotipoTecma from '../icons/logotipoTecma.svg';
import NavbarItems from './Navbar.Items';
import './Navbar.scss';

interface Props {
  handleChangeLanguage: (lang?: string) => void;
  mobileMode?: boolean;
  handleCloseDrawer?: () => void;
}

const Navbar = ({ mobileMode = false, handleChangeLanguage, handleCloseDrawer }: Props) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isExpandMenuTooltipDisabled, setIsExpandMenuTooltipDisabled] = useState(!isCollapsed);

  const handlePageChange = () => setIsCollapsed((el) => !el);

  useEffect(() => {
    setTimeout(() => {
      setIsExpandMenuTooltipDisabled(!isCollapsed);
    }, 200);
  }, [isCollapsed]);

  return (
    <div className={classNames('navbar-container', isCollapsed && 'collapsed')}>
      <div className='navbar-logo'>
        <ReactSVG src={logoTecma} />
      </div>

      <div className='navbar-menu'>
        <div className='navbar-menu-title'>{t('iTd.navbar.menu')}</div>
        <Tooltip
          classes={{ tooltip: 'navbar-item-tooltip' }}
          disableFocusListener
          disableTouchListener
          disableHoverListener={isExpandMenuTooltipDisabled}
          title={t('iTd.navbar.expandMenu')}
          placement='right'
        >
          <ButtonBase disableRipple className='navbar-collapse-button' onClick={mobileMode ? handleCloseDrawer : handlePageChange}>
            <Icon className='navbar-collapse-button-icon' iconName={mobileMode ? 'x' : 'contract'} />
          </ButtonBase>
        </Tooltip>
      </div>
      <NavbarItems mobileMode={mobileMode} collapsedMode={isCollapsed} handleChangeLanguage={handleChangeLanguage} />
      {!isCollapsed && (
        <div className='navbar-footer-powered'>
          <div className='navbar-footer-powered-text'>Powered by</div>
          <ReactSVG className='navbar-footer-powered-icon' src={logotipoTecma} />
        </div>
      )}
    </div>
  );
};

export default memo(Navbar);
