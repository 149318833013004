import { ReactNode } from 'react';

import { observer } from 'mobx-react';

interface Props {
  children: ReactNode;
}

export const ProviderAuth = observer(({ children }: Props) => {
  return <>{children}</>;
});
