import React from 'react';

import { ErrorPage } from '@tecma/ds';
import { injectContextHeaders } from '@tecma/logs-fe';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import App from './App';
import { Providers } from './components/App.Providers';
import { IS_DEVELOPMENT } from './constants/general';
import reportWebVitals from './reportWebVitals';

import './theme/global.scss';

if (!IS_DEVELOPMENT) injectContextHeaders();

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <ErrorBoundary FallbackComponent={() => <ErrorPage logo='' />}>
        <App />
      </ErrorBoundary>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
