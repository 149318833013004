import { gql, useApolloClient } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

// #region ::: QUERY :::
export interface PaginatedClientsParams {
  page: number;
  perPage?: number;
  searchText?: string;
}

export type BackOfficeUserRole = 'VENDOR' | 'VENDOR_MANAGER' | 'ACCOUNT_MANAGER' | 'FRONT_OFFICE' | 'PROPRIETA' | 'BUILDING_MANAGER';

export interface VendorListParams {
  projectIds: string[];
  roles?: BackOfficeUserRole[];
  isDisabled?: boolean;
}

const GET_VENDORS = gql`
  query getBackOfficeUsersCorporate($projectId: ID!, $filter: CorporateBOUsersFilter!, $params: PaginatedBOUsersParams!) {
    getBackOfficeUsersCorporate(projectId: $projectId, filter: $filter, params: $params) {
      data {
        id
        firstName
        lastName
        email
        project_ids
        role
        isDisabled
      }
    }
  }
`;
// #endregion

interface Params {
  filter: VendorListParams;
  GraphQL: ReturnType<typeof useApolloClient>;
}

export const thunkGetVendors = createAsyncThunk('vendor/list', async ({ filter, GraphQL }: Params, thunkAPI) => {
  try {
    // @ts-ignore
    const { demoProjectId } = thunkAPI.getState().calendar;
    const response = await GraphQL.query({
      query: GET_VENDORS,
      variables: {
        projectId: demoProjectId,
        filter: {
          ...filter,
          roles: filter.roles || ['VENDOR', 'VENDOR_MANAGER'],
        },
        params: {
          sortField: 'FULL_NAME',
          sortOrder: 1,
        },
      },
    });

    if (response.data.getBackOfficeUsersCorporate) {
      return thunkAPI.fulfillWithValue(response.data.getBackOfficeUsersCorporate.data);
    }
    throw new Error('General Calendar Error');
  } catch (error) {
    return thunkAPI.rejectWithValue('General Calendar Error');
  }
});
