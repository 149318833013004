import { ReactNode, useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { observer } from 'mobx-react';

import inizializeAnalytics from '../helpers/analytics';
import { useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';

interface Props {
  children: ReactNode;
}

export const ProviderAnalytics = observer(({ children }: Props) => {
  const environment = useAppSelector(sliceEnvironment.selectors.environment);

  useEffect(() => {
    if (environment.REACT_APP_GOOGLE_TAG_MANAGER_CODE) {
      inizializeAnalytics({
        gtmId: environment.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
      });
      console.log('Google Tag Manager initialized', environment.REACT_APP_GOOGLE_TAG_MANAGER_CODE);
    }

    if (
      environment.REACT_APP_NAMESPACE === 'biz-tecma-prod' &&
      environment.REACT_APP_DATADOG_APPLICATION_ID &&
      environment.REACT_APP_DATADOG_CLIENT_TOKEN
    ) {
      datadogRum.init({
        applicationId: environment.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: environment.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'itd',
        env: environment.REACT_APP_NAMESPACE,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      console.log('Datadog initialized', environment.REACT_APP_DATADOG_APPLICATION_ID);
    }
  }, [
    environment.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
    environment.REACT_APP_NAMESPACE,
    environment.REACT_APP_DATADOG_APPLICATION_ID,
    environment.REACT_APP_DATADOG_CLIENT_TOKEN,
  ]);

  return <>{children}</>;
});
