import { useEffect } from 'react';

import { useLocation, useNavigationType } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== 'POP') {
      const bodyContainer = document.getElementById('body-container');
      if (bodyContainer) bodyContainer.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};

export default ScrollToTop;
