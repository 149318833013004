import { z } from 'zod';

export const SchemaEnvironment = z
  .object({
    REACT_APP_HOST: z.string(),
    REACT_APP_DATADOG_APPLICATION_ID: z.string(),
    REACT_APP_DATADOG_CLIENT_TOKEN: z.string().optional(),
    REACT_APP_BUCKET_BASEURL: z.string(),
    REACT_APP_APOLLO_URI: z.string(),
    REACT_APP_NAMESPACE: z.enum(['biz-tecma-prod', 'biz-tecma-pre-prod', 'biz-tecma-dev1', 'biz-tecma-test', 'biz-tecma-demo-prod']),
    REACT_APP_GOOGLE_TAG_MANAGER_CODE: z.string(),
    REACT_APP_INFO_MAIL: z.string(),
    REACT_APP_REPORT_API_BASE_URL: z.string(),
    FEATURE_FLAG_CALENDAR: z.coerce.boolean(),
    REACT_APP_COOKIEBOT_ID: z.string().optional(),
    REACT_APP_CLARITY_ID: z.string().optional(),
  })
  .strict();
