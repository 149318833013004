import { createSlice } from '@reduxjs/toolkit';

import type { Snackbar, Modal } from '@module-calendar/declarations';

export const sliceDialogs = createSlice({
  initialState: {} as {
    snackbar: Snackbar;
    modal: Modal;
  },
  name: 'dialogs',
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
  },
  selectors: {
    snackbar: (state) => state.snackbar,
    modal: (state) => state.modal,
  },
});
