import React, { useCallback } from 'react';

import { Snackbar } from '@tecma/ds';
import { observer } from 'mobx-react';

import { useStoreMobx } from 'mobx/helpers';
import './App.Snackbar.scss';

const AppSnackbar = observer(() => {
  const store = useStoreMobx();

  const handleOnClose = useCallback(() => {
    store.closeSnackbar();
  }, [store]);

  return (
    <Snackbar
      handleClose={handleOnClose}
      open={store.snackbar?.open}
      type={store.snackbar?.type}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      title={store.snackbar?.title}
      description={store.snackbar?.description}
    />
  );
});

export default AppSnackbar;
